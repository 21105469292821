<template>
  <div class="flex min-h-full flex-1">
    <div
      class="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
    >
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <h1
          class="text-4xl font-bold leading-tight tracking-tight text-gray-900 mb-8 text-center"
        >
          Get started
        </h1>
        <div class="flex justify-center gap-x-4">
          <ButtonLogin
            class-str="inline-flex justify-center items-center rounded-md bg-primary-50 px-3.5 py-2.5 font-semibold text-primary-400 shadow-sm hover:bg-primary-100 w-36"
          />
          <a
            :href="signUpUrl"
            target="_blank"
            rel="noopener noreferrer"
            class="inline-flex justify-center items-center rounded-md bg-primary-50 px-3.5 py-2.5 font-semibold text-primary-400 shadow-sm hover:bg-primary-100 w-36"
            data-cy="sign-up-button"
            >Sign up</a
          >
        </div>
      </div>
    </div>
    <div class="relative hidden w-0 flex-1 lg:block">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        src="~/assets/img/centralised-communication.png"
        alt="Centralised communication through DatAds"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { SIGN_UP_URL } from "~/shared/constants";

definePageMeta({
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: "/",
  },
  layout: "not-logged-in",
});

const signUpUrl = SIGN_UP_URL;
</script>
